export class Login {
    // public userName:string;
    // public password:string;

    public code:string;
    public passwd:string;

    
    //change password
    public currentpassword: string;
    public newpassword: string;
    public confirmpassword: string;
    public login_employee_username: string;
}
